import React from 'react';
import Layout from '../components/Layout';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
import PageHeader from '../components/PageHeader';
import DetailHeader from '../components/DetailHeader';
import Footer from '../components/Footer';
import ResponsiveImage from '../base/ResponsiveImage';
import { Box } from '@3pc/layout-components-react';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';
import { footerHeight } from '../components/Footer';
import { maxWidthVals } from '../styles/container';

const ContentContainer = styled.div`
  background: ${colors.white};
  padding: 20px;
  margin: 0 auto 10px auto;
  width: calc(100% - 40px);
  max-width: ${maxWidthVals.uiMaxWidth};
  border-radius: 4px;
  position: relative;
  z-index: 0;
  margin-bottom: ${footerHeight.footerMobile};

  &::before {
    content: '';
    position: absolute;
    left: -1px;
    top: -1px;
    width: calc(100% + 2px);
    height: 100%;
    background: linear-gradient(180deg, ${colors.blue} 0%, ${colors.white} 85%);
    border-radius: 4px;
    z-index: -2;
  }

  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: calc(100% - 2px);
    height: 100%;
    background: ${colors.white};
    border-radius: 3px;
    z-index: -1;
  }

  & > *:not(ol) {
    margin-bottom: 20px;
    padding-left: 30px;
  }

  p {
    font-size: 0.875em;
    line-height: 1.313em;

    &:only-child {
      padding-left: 0;
    }
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }

  ol li {
    counter-increment: my-awesome-counter;
    display: flex;
    margin-bottom: 20px;
    font-size: 0.875em;
    line-height: 1.313em;
    position: relative;
    padding-left: 30px;
    flex-direction: column;

    &::before {
      content: '(' counter(my-awesome-counter) ')';
      display: flex;
      white-space: pre;
      font-weight: bold;
      font-size: 0.875em;
      line-height: 1.313em;
      position: absolute;
      left: 0;
    }

    ol {
      list-style-type: lower-alpha;
      margin-top: 20px;

      li::before {
        content: '(' counter(my-awesome-counter, lower-alpha) ')';
      }
    }
  }
`;

export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        slug
        title
        subtitle
      }
      body
    }
    image: file(relativePath: { eq: "13.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 3.10303
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
    mobileImage: file(relativePath: { eq: "13.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          aspectRatio: 1.77778
          transformOptions: { grayscale: true, cropFocus: NORTH }
          placeholder: BLURRED
        )
      }
    }
  }
`;

const DetailPageTemplate = (data) => {
  const defaultImage = data.data.image.childImageSharp.gatsbyImageData;
  const mobileImage = data.data.mobileImage.childImageSharp.gatsbyImageData;
  const altTag =
    'Farbfotografie des Gerechtigkeitsbrunnens mit Figur der Justitia, die Schwert und Waage in den Händen hält';

  return (
    <>
      <PageHeader
        headerTitle={data.data.mdx.frontmatter.title}
        pageType={'detail-page'}
      />
      <Layout>
        <Box as="article" position="relative">
          <ResponsiveImage
            defaultImage={defaultImage}
            mobileImage={mobileImage}
            altTag={altTag}
          />
          <DetailHeader
            pageType="detail-page"
            headerTitle={data.data.mdx.frontmatter.title}
            headerInfo={data.data.mdx.frontmatter.subtitle}
            slug={data.data.mdx.frontmatter.slug}
          />
          <ContentContainer>
            <MDXRenderer>{data.data.mdx.body}</MDXRenderer>
          </ContentContainer>
        </Box>
      </Layout>
      <Footer />
    </>
  );
};

export default DetailPageTemplate;
